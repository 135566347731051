<template>
  <v-apex-chart
    type="bar"
    :series="chart.series"
    :options="chart.options"
    card_height="230"
    height="100%"
    tile
    animated
    :toolbar="false"
  ></v-apex-chart>
</template>

<script>
export default {
  name: "categories",
  components: {
    VApexChart: () => import("@/components/apexChart")
  },
  data: () => ({
    dataList: [
      [50, 25, 10, 5],
      [25, 20, 15, 10],
      [100, 90, 80, 70],
      [4, 3.5, 3, 2.5],
      [200, 100, 50, 25]
    ],
    currentSeries: [5, 10, 25, 50],
    event: null
  }),
  mounted() {
    this.currentSeries = [0, 0, 0, 0];
    setTimeout(() => {
      this.currentSeries = [5, 25, 50, 100];
    }, 1000);
  },
  computed: {
    chart() {
      let options = {},
        series = [],
        categories = [],
        data = [];
      let years = ["Year 1", "Year 2", "Year 3", "Year 4"],
        values = this.currentSeries;
      years.forEach((yearMod, index) => {
        categories.push(yearMod.toString());
        data.push(values[index]);
      });
      options = {
        colors: [this.$vuetify.theme.currentTheme.primary],
        chart: {
          type: "bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "bottom"
            }
            // columnWidth: Math.round((categories.length / 20) * 100) + "%"
          }
        },
        dataLabels: {
          enabled: false,
          offsetY: 10,
          style: {
            fontSize: "12px",
            colors: [this.$vuetify.theme.currentTheme.primary]
          },
          formatter: val => {
            return Math.round((val / data.reduce((a, b) => a + b)) * 100) + "%";
          }
        },
        xaxis: {
          categories,
          position: "bottom",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          labels: {
            show: false
            // formatter: (val)=> {
            //   return "$ " + this.moneyFormat(val);
            // }
          }
        },
        tooltip: {
          enabled: false
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: false,
            formatter: val => {
              return val + "%";
            }
          }
        }
      };
      series = [
        {
          name: "Sales",
          data
        }
      ];
      return { options, series };
    }
  }
};
</script>

<style scoped></style>
